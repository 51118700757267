// eslint-disable-next-line func-names
export default async function (context) {
    const {
        TENANT_QUERY_PARAM,
        DEFAULT_TENANT_VALUE,
    } = context.$config;

    const tenantQueryParam = context.query[TENANT_QUERY_PARAM];
    const tenantStoreParam = context.store.state.tenant.environment;
    const tenantValue = tenantQueryParam || tenantStoreParam || DEFAULT_TENANT_VALUE;

    if (tenantQueryParam && tenantQueryParam !== tenantStoreParam) {
        context.store.dispatch('tenant/set', tenantQueryParam);
    } else if (!tenantStoreParam) {
        // If nothing is set in the store, set the store
        context.store.dispatch('tenant/set', tenantValue);
    }
}
