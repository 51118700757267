
/**
 * for dark background, set class="text-white" on this component
 */
export default {
    name: 'EsLogo2024',
    props: {
        /**
         * Width
         */
        width: {
            type: String,
            default: '205px',
            required: false,
        },
        /**
         * Height
         */
        height: {
            type: String,
            default: '45px',
            required: false,
        },
    },
};
