export const VARIANT_PRIMARY = 'primary';
export const VARIANT_SECONDARY = 'secondary';
export const VARIANT_SUCCESS = 'success';
export const VARIANT_DANGER = 'danger';
export const VARIANT_WARNING = 'warning';
export const VARIANT_INFO = 'info';
export const VARIANT_LIGHT = 'light';
export const VARIANT_DARK = 'dark';

export const MESSAGE_VARIANTS = {
    [VARIANT_PRIMARY]: VARIANT_PRIMARY,
    [VARIANT_SECONDARY]: VARIANT_SECONDARY,
    [VARIANT_SUCCESS]: VARIANT_SUCCESS,
    [VARIANT_DANGER]: VARIANT_DANGER,
    [VARIANT_WARNING]: VARIANT_WARNING,
    [VARIANT_INFO]: VARIANT_INFO,
    [VARIANT_LIGHT]: VARIANT_LIGHT,
    [VARIANT_DARK]: VARIANT_DARK,
};
