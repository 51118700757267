
import {
    BAlert,
} from 'bootstrap-vue';
import { IconCircleAlert, IconCircleCheck } from '@energysage/es-vue-base';

export default {
    name: 'EsMessage',
    components: {
        BAlert, IconCircleAlert, IconCircleCheck,
    },
    props: {
        dismissible: {
            type: Boolean,
            required: false,
            default: true,
        },
        // primary, secondary, success, danger, warning, info, light, dark
        variant: {
            type: String,
            required: false,
            default: 'warning',
        },
        copy: {
            type: String,
            required: false,
            default: 'Warning',
        },
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dismissCountDown: 10,
        };
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
    },
};
