/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["$axios"] }] */
// @ts-check

import jwt_decode from 'jwt-decode';

export default ({ $axios, $config }, inject) => {
    // Not setting $axios.defaults.baseURL because there are multiple

    $axios.defaults.timeout = 9000;

    /**
     * @typedef {Object} FlashProfile User data from Flash Profiles
     * @property {String} userId The Flash User ID, e.g. "c999a6de-8543-4ef8-b9ab-87bb177badcc"
     * @property {String} tenantId `es::${int|prod}`
     * @property {String} email
     * @property {Boolean} emailVerified
     * @property {String} firstName
     * @property {String} lastName
     * @property {String} phoneNumber e.g. "6175551234"
     * @property {String} createdAt e.g. "2022-10-26T21:44:58.157Z"
     *
     * @typedef {Object} FlashTokens The encoded JWT's returned for a new user
     * @property {String} accessToken
     * @property {String} idToken
     * @property {String} refreshToken
     *
     * @typedef {Object<String, String | Number | Boolean>} FlashClaims Attributes of a decoded JWT
     */

    inject('api', {
        /**
         * Create a Flash Profile for a user.
         * @param {Object} data The properties for the new user
         * @param {String} data.tenantId Must be in the form `es::${int|prod}`, likely via getTenantId
         * @param {String} data.email
         * @param {String} data.firstName
         * @param {String} data.lastName
         * @param {String} data.phoneNumber
         * @returns {Promise<FlashProfile & FlashTokens>} The user data from Flash Profiles
         */
        async createUser(data) {
            // Remove empty keys from data
            const cleanData = Object.fromEntries(
                Object.entries(data).filter(([, value]) => Boolean(value)),
            );

            return $axios.$post(
                `${$config.PROFILE_DOMAIN}/users/`,
                cleanData,
                {
                    timeout: 15000,
                },
            );
        },

        /**
         * Get the Flash Profile for a user.
         * @param {Object} args
         * @param {String} args.idToken The ID token JWT from Cognito; passed explicitly to avoid coupling to $auth
         * @returns {Promise<FlashProfile>} The user data from Flash Profiles
         */
        async getUser({ idToken }) {
            // This function is called before the user is completely logged-in,
            // so decoding the ID token is the only  way to get their user ID

            const { 'custom:flash_user_id': flashUserId } = /** @type {FlashClaims} */ (jwt_decode(idToken));
            return $axios.$get(
                `${$config.PROFILE_DOMAIN}/users/${flashUserId}`,
                {
                    headers: { Authorization: `Bearer ${idToken}` },
                },
            );
        },

        /**
         * Check if a user exists, and trigger migrator if user exists on rooftop but not Cognito.
         * @param {Object} args
         * @param {String} args.username Must be in the form `${tenantId}::${email}`, likely via getTenantUsername
         * @returns {Promise<void>}
         */
        async checkUserExists({ username }) {
            await $axios.$get(`${$config.AUTH_DOMAIN}/v1/users/${username}`);
        },

        /**
         * Set a new password for a user.
         * @param {Object} args
         * @param {String} args.username Must be in the form `${tenantId}::${email}`, likely via getTenantUsername
         * @param {String} args.password The new password for the user
         * @param {String} args.idToken The ID token JWT from Cognito; passed explicitly to avoid coupling to $auth
         * @returns {Promise<void>}
         */
        async setPassword({ username, password, idToken }) {
            await $axios.$post(
                `${$config.AUTH_DOMAIN}/v1/users/${username}/set-password`,
                { password },
                {
                    headers: { Authorization: `Bearer ${idToken}` },
                },
            );
        },

    });
};
