// @ts-check
/**
 * Refresh the Flash ID token if needed, and ensure that $auth.user and loggedIn are accurate.
 */
export default async ({ $auth, query }) => {
    // Allow other frontends to trigger a refresh of the ID token based on their own logic,
    // e.g. token will expire in less than an hour.
    const forceRefresh = query.refresh === '1';

    try {
        // fetchUser will also call getIdToken if the user isn't already set,
        // but this explicit call ensures that the ID token is refreshed.
        await $auth.getIdToken({ forceRefresh });
        await $auth.fetchUser();
    } catch {
        // If fetching the user failed, the user was probably not logged in, or the refresh token expired
        // (and the user is now logged out). Assuming user will be redirected to /login by pages.
    }
};
