
import {
    BLink,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
} from 'bootstrap-vue';
import EsLogo from '@/components/EsLogo.vue';
import { IconPerson } from '@energysage/es-vue-base';

export default {
    name: 'EsNavbar',
    components: {
        BLink, BNavbar, BNavbarBrand, BNavbarNav, BNavItemDropdown, BDropdownItem, EsLogo, IconPerson,
    },
    props: {
        user: {
            type: Object,
            required: false,
            default() {
                return {
                    loggedIn: false,
                    email: null,
                };
            },
        },
    },
};
