// @ts-check
import { Amplify, Auth } from 'aws-amplify';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';

/**
 * Handle auth-related tasks like login, getting/refreshing the Flash ID token, and maintaining logged-in state.
 *
 * See also middleware/auth.js.
 *
 * The API and behavior of $auth is based on Nuxt Auth (@nuxtjs/auth-next), and our own custom FlashScheme,
 * which was used to build flash-ui. However, Nuxt Auth is outdated, poorly maintained, and confusing.
 * We were also duplicating some of its behavior, and it was duplicating some of aws-amplify's behavior.
 *
 * This plugin was written to replace Nuxt Auth, while minimizing changes to the pages that used it.
 * There are probably many ways it could be made better.
 *
 * For more about aws-amplify, see ~/docs/index.md#aws-amplify.
 */
export default ({ $config, $api, store }, inject) => {
    Amplify.configure({
        Auth: {
            region: $config.AWS_COGNITO_REGION,
            userPoolId: $config.AWS_COGNITO_USER_POOL_ID,
            userPoolWebClientId: $config.AWS_COGNITO_CLIENT_ID,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        },
    });

    const GLOBAL_COOKIE_NAME = 'flash.id_token';
    const GLOBAL_COOKIE_SETTINGS = {
        domain: $config.COOKIE_DOMAIN,
        secure: $config.COOKIE_SECURE,
    };

    inject('auth', {

        /**
         * Log the user in to Cognito with a password, and get their Flash Profile.
         *
         * This will create a Cognito session in localStorage, which includes the ID and refresh tokens.
         * However, those values should only be accessed through Auth functions.
         *
         * For OTP login, see /pages/login/otp.vue::handleCodeSubmit()
         *
         * @param {Object} args
         * @param {String} args.username Must be in the form `${tenantId}::${email}`, likely via getTenantUsername
         * @param {String} args.password The user's password
         * @returns {Promise<void>}
         */
        async login({ username, password }) {
            if (!username) {
                throw new Error('username missing');
            }

            if (password) {
                await Auth.signIn(username, password);
            } else {
                throw new Error('password missing');
            }

            await this.fetchUser();
        },

        /**
         * Set $auth.user (and $auth.loggedIn) after the user is signed-in to Cognito.
         * @returns {Promise<void>}
         */
        async fetchUser() {
            // Only set the user once per session
            if (this.user) return;

            performance.mark('start-getUser');
            const userAttributes = await $api.getUser({ idToken: await this.getIdToken() });
            if (!userAttributes) {
                throw new Error('No user info found');
            }
            performance.mark('end-getUser');

            const getUserMeasure = performance.measure('getUser', 'start-getUser', 'end-getUser');
            datadogRum.addAction('getUser', { duration: getUserMeasure.duration });

            const user = {
                flashUserId: userAttributes.userId,
                email: userAttributes.email,
                emailVerified: userAttributes.emailVerified,
                firstName: userAttributes.firstName,
                lastName: userAttributes.lastName,
                tenantId: userAttributes.tenantId,
            };

            store.commit('setUser', user);
        },

        /**
         * Get the Flash ID token, and set the global cookie.
         *
         * Assumes that a Cognito session has been established, e.g. by $auth.login().
         *
         * This takes advantage of Auth.currentAuthenticatedUser (and currentSession) handling token refresh
         * in the background. So, whenever we call those, we need to update the global cookie. Yes, this is
         * a side-effect, but it seems like a reasonable trade-off to not have to worry about token refresh.
         *
         * If the logic in here ends up being needed elsewhere in this plugin, extract some methods.
         *
         * @param {Object} args
         * @param {Boolean} [args.forceRefresh] Refresh the ID token, even if it's not expired
         * @returns {Promise<String>}
         */
        async getIdToken({ forceRefresh } = {}) {
            let cognitoSession;
            try {
                // This will refresh the access token and ID token if either one of them expires,
                // assuming the refresh token is stil valid.
                const cognitoUser = await Auth.currentAuthenticatedUser();

                if (forceRefresh) {
                    // HACK: This is a private method that shouldn't be called directly, and it might also result in
                    // a redundant refresh if currentAuthenticatedUser already did one, but it's quick to use,
                    // and aws-amplify v6 has a more direct way to force refresh.
                    await cognitoUser.refreshSessionIfPossible();
                }

                cognitoSession = cognitoUser.getSignInUserSession();
            } catch (err) {
                // With no session, the user is no longer logged in, so update local state to reflect that
                this.reset();

                // Leaving it to the caller to show a message, redirect, etc.
                throw err;
            }

            const idToken = cognitoSession.getIdToken().getJwtToken();

            Cookies.set(
                GLOBAL_COOKIE_NAME,
                idToken,
                {
                    ...GLOBAL_COOKIE_SETTINGS,
                    // Our backends use the ID token to authorize API requests, so it needs to live longer than
                    // the browser session. Setting 1 day cookie expiration to match Cognito token expiration.
                    // However, the token may expire sooner, depending on when this function is called.
                    // There might also be a case for the cookie expiration matching the refreshToken expiration
                    // (4 weeks), e.g. to allow frontends to determine if the user has previously logged in.
                    expires: 1,
                },
            );

            return idToken;
        },

        /**
         * Get the Flash Profile data for the logged-in user.
         * @returns {Object} The user data set in fetchUser()
        */
        get user() {
            // Assuming user will be set/cleared by other methods.
            // We could also check if Auth.currentSession() is valid, but that's async, which complicates the caller.
            return store.state.user;
        },

        /**
         * Convenience attribute, carried over from Nuxt Auth API.
         */
        get loggedIn() {
            return Boolean(this.user);
        },

        /**
         * Log the user out of Cognito, and clear local state.
         */
        async logout() {
            try {
                await Auth.signOut();
            } catch {
                // signOut failure shouldn't prevent logout
            }

            this.reset();
        },

        /**
         * Clear local state.
         */
        reset() {
            store.commit('setUser', null);
            Cookies.remove(GLOBAL_COOKIE_NAME, GLOBAL_COOKIE_SETTINGS);
        },

        /**
         * Log the user's ID early, to help us find sessions where the user fails to log in.
         * TODO: Combine this and logEmail
         * @param {String} id The user's email
         */
        logUserId(id) {
            datadogRum.setUserProperty('id', id);
            Sentry.setUser({ id });
        },

        /**
         * Log the user's email early, to help us find sessions where the user fails to log in.
         * TODO: Combine this and logUserId
         * @param {String} email The user's email
         */
        logEmail(email) {
            datadogRum.setUserProperty('email', email);
            Sentry.setUser({ email });
        },

        /**
         * Log errors to Sentry and Datadog.
         *
         * This method is weird; it's a copy of logging functionality from the old Nuxt Auth implementation,
         * to reduce impact on the calling code. It should probably be its own plugin, and we should revisit how
         * logging is handled; @see https://energysage.atlassian.net/browse/CORE-5543.
         *
         * @param {Error} error The original error object
         * @param {Object} args
         * @param {String} args.method The name of the method where the error occurred
         */
        logError(error, { method }) {
            // Somehow, this still shows the caller in the stack trace in Sentry and Datadog,
            // though it doesn't look like it would.
            const namedError = new Error(error.message);
            namedError.name = method || 'unknown';

            Sentry.captureException(namedError);
            datadogRum.addError(namedError, { method });

            // Log auth errors to the console just in case
            // eslint-disable-next-line no-console
            console.error(error, method);
        },
    });
};
