export const state = () => ({
    environment: '',
});

export const mutations = {
    setEnvironment(state, environment) {
        state.environment = environment;
    },
};

export const actions = {
    async set({ commit }, environment) {
        commit('setEnvironment', environment);
    },
};

export const getters = {
};
