import { MESSAGE_VARIANTS } from '@/utils/constants';

export const state = () => ({
    messages: [],
});

export const mutations = {
    pushMessage(state, newMessage) {
        state.messages = [newMessage, ...state.messages];
    },
    popMessage(state, messageId) {
        const messageToRemove = state.messages.find((message) => {
            if (message.id === messageId) {
                return {
                    message,
                };
            }
            return false;
        });
        if (messageToRemove) {
            state.messages = state.messages.filter((message) => message.id !== messageToRemove.id);
        }
    },
};

export const actions = {
    async push({ commit }, newMessage) {
        const validMessageVariants = Object.keys(MESSAGE_VARIANTS);
        const isMessageVariantValid = validMessageVariants.includes(newMessage.variant);
        const newMessageObj = {
            id: `${new Date().getTime()}`,
            copy: newMessage.copy,
            variant: isMessageVariantValid ? newMessage.variant : MESSAGE_VARIANTS.info,
        };
        commit('pushMessage', newMessageObj);
    },
    async pop({ commit }, messageId) {
        commit('popMessage', messageId);
    },
};

export const getters = {
};
