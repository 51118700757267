// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';

export default ({ $config }) => {
    if ($config.DATADOG_DISABLED !== true) {
        const cognitoLoginUrl = 'https://cognito-idp.us-east-1.amazonaws.com';

        datadogRum.init({
            applicationId: '2f383477-aad9-4bf7-8e20-1497ba1d9525',
            clientToken: 'pub2ba36dbf3032fbdd7d2d72b169379154',
            site: 'datadoghq.com',
            service: 'flash-ui',
            env: $config.DEPLOY_ENV,
            version: $config.PKG_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'allow',
            allowedTracingUrls: [
                $config.PROFILE_DOMAIN,
                $config.AUTH_DOMAIN,
                cognitoLoginUrl,
            ],
        });
        datadogRum.startSessionReplayRecording();
    }
};
