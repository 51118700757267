import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';

export default async ({ $auth, store }) => {
    // Previously, nextLocation.{url,route} were persisted as query params, which could aid observabilty.
    // Saving the current state (after other middleware) as context hopefully accomplishes the same thing.
    datadogRum.setGlobalContextProperty('vuex', store.state);
    Sentry.setContext('vuex', store.state);

    if (!$auth.user) {
        return;
    }
    datadogRum.setUser({
        id: $auth.user.flashUserId,
        email: $auth.user.email,
    });
    Sentry.setUser({
        id: $auth.user.flashUserId,
        email: $auth.user.email,
        ip_address: '{{auto}}',
    });
};
