
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import EsMessage from '@/components/EsMessage.vue';

export default {
    name: 'LayoutError',
    components: {
        BContainer, BRow, BCol, EsMessage,
    },
    layout: 'default',
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    computed: {
        messages() {
            return this.$store.state.messages.messages;
        },
    },
};
