
import { BContainer } from 'bootstrap-vue';
import EsNavbar from '@/components/EsNavbar.vue';
import EsMessage from '@/components/EsMessage.vue';

export default {
    name: 'LayoutDefault',
    components: {
        BContainer, EsNavbar, EsMessage,
    },
    computed: {
        userObj() {
            if (this.$auth.loggedIn) {
                return {
                    loggedIn: true,
                    email: this.$auth.user.email,
                };
            }
            return {};
        },
        messages() {
            return this.$store.state.messages.messages;
        },
    },
};
