/**
 * Enable redirect back to other frontends after logging in via Flash UI.
 *
 * For example:
 * 1. https://www.energysage.com/market/ (logged out)
 * 2. https://www.energysage.com/login/ (logged out)
 * 3. https://accounts.energysage.com/login?next=https://www.energysage.com/market/ (submit credentials)
 * 4. https://accounts.energysage.com/login-success (logged in)
 * 5. https://www.energysage.com/market/ (logged in)
 */
export default ({ $config, query, store }) => {
    const {
        NEXT_URL_QUERY_PARAM,
        DEFAULT_REDIRECT_URL,
    } = $config;

    // Set the store if it's empty or if it's different from the query param
    const nextUrlStore = store.state.nextLocation.url;
    const nextUrl = query[NEXT_URL_QUERY_PARAM] || nextUrlStore || DEFAULT_REDIRECT_URL;
    if (!nextUrlStore || nextUrlStore !== nextUrl) {
        store.commit('nextLocation/setUrl', nextUrl);
    }
};
