export const state = () => ({
    /**
     * External URL to redirect to after user action (e.g. login), set by next-url middleware.
     * For example: 'https://www.energysage.com/market/'.
     */
    url: '',

    /**
     * Internal route name to redirect to after user action (e.g. login).
     * For example: 'email-confirm'. This requires the user to log in first, but we don't want to clobber state.url.
     *
     * 1. https://accounts.energysage.com/email/confirm?next=https://www.energysage.com/market/ (logged out)
     * 2. https://accounts.energysage.com/login (submit credentials)
     * 3. https://accounts.energysage.com/login/success (logged in)
     * 4. https://accounts.energysage.com/email/confirm (logged in, submit confirmation code)
     * 5. https://accounts.energysage.com/login/success (logged in)
     * 6. https://www.energysage.com/market/ (logged in)
     */
    route: '',
});

export const mutations = {
    setUrl(state, url) {
        state.url = url;
    },
    removeUrl(state) {
        state.url = '';
    },
    setRoute(state, name) {
        state.route = name;
    },
    removeRoute(state) {
        state.route = '';
    },
};

export const actions = {
};

export const getters = {
};
